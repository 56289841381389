import { GetStaticProps } from "next";
import { HomePageComponent } from "pages-components/HomePageComponent";

import BannersApi from "@/api/BannersApi";
import { BannersResponse } from "@/api/generatedTypes";

interface HomePageProps {
  banners?: BannersResponse;
}

const HomePage = ({ banners }: HomePageProps) => {
  return <HomePageComponent banners={banners} />;
};

export const getStaticProps: GetStaticProps = async () => {
  let banners: BannersResponse | null = null;

  try {
    const response = await BannersApi.all();
    banners = response.data;
  } catch (e) {
    //
  }
  return { props: { banners }, revalidate: 300 };
};

export default HomePage;
