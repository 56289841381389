import { parseCookies } from "nookies";
import { useEffect, useState } from "react";

export const useIsAdminRole = () => {
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const { role } = parseCookies();
    if (role === "admin") {
      setIsAdmin(true);
    }
  }, []);

  return isAdmin;
};
