import { useInfiniteQuery } from "@tanstack/react-query";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";

import { BannersResponse } from "@/api/generatedTypes";
import ProductApi, { PRODUCTS_PER_PAGE } from "@/api/ProductsApi";
import { CalendarModal } from "@/components/admin/modals";
import { useFilterHandlers } from "@/components/filter/useFilterHandlers";
import { Header } from "@/components/Header";
import { AddIcon, CalendarIcon } from "@/components/icons";
import { LayoutProducts } from "@/components/LayoutProducts";
import { Products } from "@/components/Products";
import { GoUpButton } from "@/components/ui-components/GoUpButton";
import { goUpFunctionsState, goUpState } from "@/hooks/useGoUpListener";
import { useIsAdminRole } from "@/hooks/useIsAdminRole";
import { useToggle } from "@/hooks/useToggle";

import styles from "./HomePageComponent.module.scss";

interface HomePageComponentProps {
  title?: string;
  banners?: BannersResponse;
}

const HomePageComponent = ({ banners, title }: HomePageComponentProps) => {
  const [isReadyToShow, setIsReadyToShow] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const goUpVisibleState = useRecoilValue(goUpState);
  const goUpFunctions = useRecoilValue(goUpFunctionsState);

  const { setInitialFilters } = useFilterHandlers();

  const { query, push } = useRouter();
  const isAdmin = useIsAdminRole();

  const productsQuery = useInfiniteQuery(
    ["products"],
    ({ pageParam = 0 }) => {
      return ProductApi.all({
        offset: pageParam,
      });
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: isReadyToShow,
      getNextPageParam: (lastPage, allPages) => {
        const itemsLength = lastPage.data.length;
        if (itemsLength === PRODUCTS_PER_PAGE)
          return lastPage.data.length * allPages.length;

        return undefined;
      },
    }
  );

  const {
    isOpen: isCalendarOpen,
    open: openCalendar,
    close: closeCalendar,
  } = useToggle();

  useEffect(() => {
    if (selectedDate) {
      push({
        pathname: "/",
        query: { ...query, date: selectedDate },
      });
    }
  }, [selectedDate]);

  useEffect(() => {
    if (isReadyToShow) return;

    setInitialFilters({
      categories: [],
      collections: [],
      sizes: [],
      colors: [],
      discounts: [],
      search: "",
    });
    setIsReadyToShow(true);
  }, [isReadyToShow]);

  const productsPages = productsQuery.data?.pages;
  const firstProductsPage =
    (productsQuery.data && productsQuery.data.pages?.[0].data) || [];
  const isProductEmpty =
    (firstProductsPage.length === 0 && productsQuery.isFetched) || false;

  const isGoUpButtonShown = goUpVisibleState.products;
  const goUpHandler = goUpFunctions.products;

  return (
    <LayoutProducts
      title={title}
      stickyContainerSlot={
        <>
          <Header
            className={styles.header}
            isProfileButton
            isCartButton
            logoClickHandler={goUpHandler}
          />
        </>
      }
    >
      <div className={styles.contentContainer}>
        <Products
          className={styles.contentTab}
          productsPages={productsPages}
          fetchNextPage={() => productsQuery.fetchNextPage()}
          isProductEmpty={isProductEmpty}
          isBlocksEmpty={true}
          isMainPage={false}
          banners={banners}
        />
      </div>

      {isGoUpButtonShown && (
        <GoUpButton className={styles.goUp} onClick={goUpHandler} />
      )}

      {isCalendarOpen && (
        <CalendarModal
          closeCalendar={closeCalendar}
          setSelectedDate={setSelectedDate}
          selectedDate={selectedDate}
        />
      )}
      {isAdmin && (
        <button className={styles.calendarButton} onClick={openCalendar}>
          <CalendarIcon color="#928f8d" />
        </button>
      )}
      {isAdmin && (
        <Link className={styles.addButton} href="/admin/looks">
          <AddIcon color="#928f8d" />
        </Link>
      )}
    </LayoutProducts>
  );
};

export default HomePageComponent;
